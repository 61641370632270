import { QueryResult } from '@apollo/client';
import { Formik } from 'formik';
import {
  AccountProViewSetInput,
  CityProViewSetInput,
  Exact,
  GoogleReviewLinkInsertInput,
  GoogleReviewLinkSetInput,
  ProCertificationSetInput,
  ProFormUpdateFetchProOrganizationQuery,
  ProFormUpdateQuery,
  ProHolidayInsertInput,
  ProHolidaySetInput,
  ProMediaObjectInsertInput,
  ProMediaObjectSetInput,
  ProMessageInsertInput,
  ProMessageSetInput,
  ProPostalAddressSetInput,
  ProPresentationSetInput,
  ProPriceInsertInput,
  ProPriceSetInput,
  ProViewProPersonInsertInput,
  ProViewProPersonSetInput,
  ProViewProViewSetInput,
  ProViewSetInput,
  TradeProViewSetInput,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { formatE164Telephone, ToastError, ToastSuccess } from 'utils';
import { formattedPostalAddress } from 'utils/formatted-postal-address';
import {
  ProFormUpdateFields,
  ProHolidayFields,
  ProMediaObjectFields,
  ProMessageFields,
  ProPriceFields,
} from './pro-form-update-fields-type';
import { ProFormUpdateProParams } from './pro-form-update-repository';
import { proFormUpdateValidationSchema } from './pro-form-update-validation-schema';
import ProFormUpdateView from './pro-form-update-view';
import { addCrs, isPoint } from 'utils/geometry';
import removeProperties from 'utils/remove-properties';
import { isDeepEqual } from 'utils/is-deep-equal';

type ProFormUpdateContainerProps = {
  proView?: ProFormUpdateQuery['proViewByPk'];
  proPresentationId: number;
  updateGoogleReviewLink: (
    id: number,
    googleReviewLink: GoogleReviewLinkSetInput,
  ) => Promise<void>;
  updateProViewGoogleReviewLink: (
    proViewId: number,
    googleReviewLinkId: number,
  ) => Promise<void>;
  updateProMediaObject: (
    id: number,
    proMediaObject: ProMediaObjectSetInput,
  ) => Promise<void>;
  updateProMessage: (
    id: number,
    proMessage: ProMessageSetInput,
  ) => Promise<void>;
  updateProPrice: (id: number, proPrice: ProPriceSetInput) => Promise<void>;
  updateProIntervention: (proViewId: number) => Promise<void>;
  updateProHoliday: (
    id: number,
    proHoliday: ProHolidaySetInput,
  ) => Promise<void>;
  updateProViewProPerson: (
    proPersonId: number,
    proViewId: number,
    proViewProPerson: ProViewProPersonSetInput,
  ) => Promise<void>;
  updatePro: (variables: ProFormUpdateProParams) => Promise<void>;
  createSlugRedirect: (fromSlug: string, toSlug: string) => Promise<void>;
  createGoogleReviewLink: (
    googleReviewLink: GoogleReviewLinkInsertInput,
  ) => Promise<number | undefined>;
  moveContract: (
    oldProOrganizationId: number,
    newProOrganizationId: number,
    proViewId: number,
  ) => Promise<void>;
  fetchProForm: () => Promise<
    QueryResult<
      ProFormUpdateQuery,
      Exact<{
        id: number;
        yesterday?: any;
      }>
    >
  >;
  fetchProOrganization: (proOrganizationId: number) => Promise<
    QueryResult<
      ProFormUpdateFetchProOrganizationQuery,
      Exact<{
        id: number;
      }>
    >
  >;
};

const ProFormUpdateContainer: FunctionComponent<
  ProFormUpdateContainerProps
> = ({
  proView,
  proPresentationId,
  updateProHoliday,
  updateGoogleReviewLink,
  updateProViewGoogleReviewLink,
  updateProMediaObject,
  updateProMessage,
  updateProPrice,
  updatePro,
  updateProViewProPerson,
  updateProIntervention,
  createSlugRedirect,
  createGoogleReviewLink,
  fetchProForm,
  moveContract,
  fetchProOrganization,
}) => {
  const onSubmit = useCallback(
    async (values: ProFormUpdateFields) => {
      // Should submit only when proView is loaded
      if (proView) {
        // ProView
        const proViewInput: ProViewSetInput = removeProperties(
          values,
          'colleagues',
          'googleReviewLinks',
          'sectors',
          'cities',
          'proMessages',
          'proPrices',
          'trades',
          'mainAccount',
          'proHolidays',
          'proPresentation',
          'accounts',
          'proPeople',
          'proPostalAddress',
          'isFitInGuide',
        );

        // ProPresentation
        const proPresentationInput: ProPresentationSetInput = removeProperties(
          values.proPresentation,
          'proMediaObjects',
          'mainTrade',
          'secondaryTrade',
          'proOrganization',
          'certifications',
        );

        const isProOrganizationChanged =
          proPresentationInput.proOrganizationId !==
          initialValues.proPresentation.proOrganizationId;

        // Check if cities have changed to trigger proIntervention update
        const isCitiesChanged = !isDeepEqual(
          values.cities.sort((a, b) => a.id - b.id),
          initialValues.cities.sort((a, b) => a.id - b.id),
        );

        // If proOrganization changed or mainTrade changed
        if (
          isProOrganizationChanged ||
          values.proPresentation.mainTradeId !==
            initialValues.proPresentation.mainTradeId
        ) {
          const result = await fetchProOrganization(
            proPresentationInput.proOrganizationId ?? 0,
          );

          const mainTradeIds =
            result.data?.proOrganizationByPk?.proPresentations.map(
              (proPresentation) => proPresentation.mainTrade?.id,
            );

          if (mainTradeIds?.includes(values.proPresentation.mainTradeId ?? 0)) {
            ToastError(
              'Erreur',
              `Cette entreprise possède déjà une fiche : ${proView?.proPresentation.mainTrade?.name}. Veuillez ajouter une nouvelle zone sur la fiche existante. Pour plus d'infos demander au service info.`,
            );
            throw new Error(
              'Cannot link two same mainTrade on the same proOrganization',
            );
          }
        }

        if (
          initialValues.proPresentation.proOrganizationId &&
          !proPresentationInput.proOrganizationId
        ) {
          ToastError(
            'Erreur',
            "Veuillez ajouter une entreprise (une fois une entreprise ajouté il n'est plus possible de la supprimer)",
          );
          throw new Error('proOrganization not selected');
        }

        // Moving contract file to new pro organization
        if (
          isProOrganizationChanged &&
          initialValues.proPresentation.proOrganizationId &&
          proPresentationInput.proOrganizationId
        ) {
          await moveContract(
            initialValues.proPresentation.proOrganizationId,
            proPresentationInput.proOrganizationId,
            proView.id,
          ).catch((error) => {
            ToastError('Erreur', 'Impossible de déplacer le contrat');
            throw error;
          });
        }

        const proPostalAddressInput: ProPostalAddressSetInput =
          removeProperties(values.proPostalAddress, 'formatted', '__typename');

        // GoogleReviewLinks
        // Determine which objects should be inserted and format it for the query (insert objects that do not contains an id)
        const insertGoogleReviewLinks = values.googleReviewLinks
          .filter((googleReviewLink) => !googleReviewLink.id)
          .map((googleReviewLink) => ({
            ...googleReviewLink,
            proPresentationId: proPresentationId,
          }));

        Promise.all(
          insertGoogleReviewLinks.map(async (googleReviewLink) => {
            const googleReviewLinkId = await createGoogleReviewLink({
              ...googleReviewLink,
            });

            googleReviewLink.proViewIds?.forEach(async (proViewId) => {
              googleReviewLinkId &&
                (await updateProViewGoogleReviewLink(
                  proViewId,
                  googleReviewLinkId,
                ));
            });
          }),
        ).catch((error) => {
          ToastError(
            'Erreur',
            "Impossible d'enregistrer la fiche (insert googleReviewLinks)",
          );
          throw error;
        });

        // Determine which objects need to be updated (objects which are already in the database and therefore which contain an id)
        const updateGoogleReviewLinks = values.googleReviewLinks.filter(
          (googleReviewLink) => googleReviewLink.id,
        );

        // Get all ids before any modifications
        const googleReviewLinksInitialIds =
          proView.proPresentation.googleReviewLinks.map(
            (googleReviewLink) => googleReviewLink.id,
          );

        // Get all ids after any modifications
        const googleReviewLinksIds = values.googleReviewLinks.map(
          (googleReviewLink) => googleReviewLink.id,
        );

        const deleteGoogleReviewLinks = googleReviewLinksInitialIds.filter(
          (id) => !googleReviewLinksIds.includes(id),
        );

        // Update entities who need to be updated
        await Promise.all(
          updateGoogleReviewLinks.map(async (googleReviewLink) => {
            if (googleReviewLink.id) {
              await updateGoogleReviewLink(googleReviewLink.id, {
                url: googleReviewLink.url,
              });

              googleReviewLink.proViewIds?.forEach(async (proViewId) => {
                googleReviewLink.id &&
                  (await updateProViewGoogleReviewLink(
                    proViewId,
                    googleReviewLink.id,
                  ));
              });
            }
          }),
        ).catch((error) => {
          ToastError(
            'Erreur',
            "Impossible d'enregistrer la fiche (update googleReviewLinks)",
          );
          throw error;
        });

        // ProHolidays
        // Determine which objects should be inserted and format it for the query (insert objects that do not contains an id)
        const insertProHolidays = values.proHolidays
          .filter((proHoliday) => !proHoliday.id)
          .map(
            (proHoliday): ProHolidayInsertInput => ({
              ...proHoliday,
              proViewId: proView.id,
            }),
          );

        // Determine which objects need to be updated (objects which are already in the database and therefore which contain an id)
        const updateProHolidays = values.proHolidays.filter(
          (proHoliday) => proHoliday.id,
        );

        // Get all ids before any modifications
        const proHolidaysInitialIds = proView.proHolidays.map(
          (proHoliday) => proHoliday.id,
        );

        // Get all ids after any modifications
        const proHolidaysIds = values.proHolidays.map(
          (proHoliday) => proHoliday.id,
        );

        // Determine which objects should be delete from the database (difference between before/after modifications)
        const deleteProHolidays = proHolidaysInitialIds.filter(
          (id) => !proHolidaysIds.includes(id),
        );

        // Update entities who need to be updated
        await Promise.all(
          updateProHolidays.map(async (proHoliday) => {
            if (proHoliday.id) {
              await updateProHoliday(proHoliday.id, proHoliday);
            }
          }),
        ).catch((error) => {
          ToastError(
            'Erreur',
            "Impossible d'enregistrer la fiche (update proHolidays)",
          );
          throw error;
        });

        // ProMessages
        // Determine which objects should be inserted and format it for the query (insert objects that do not contains an id)
        const insertProMessages = values.proMessages
          .filter((proMessage) => !proMessage.id)
          .map(
            (proMessage): ProMessageInsertInput => ({
              ...proMessage,
              proViewId: proView.id,
            }),
          );

        // Determine which objects need to be updated (objects which are already in the database and therefore which contain an id)
        const updateProMessages = values.proMessages.filter(
          (proMessage) => proMessage.id,
        );

        // Get all ids before any modifications
        const proMessagesInitialIds = proView.proMessages.map(
          (proMessage) => proMessage.id,
        );

        // Get all ids after any modifications
        const proMessagesIds = values.proMessages.map(
          (proMessage) => proMessage.id,
        );

        // Determine which objects should be delete from the database (difference between before/after modifications)
        const deleteProMessages = proMessagesInitialIds.filter(
          (id) => !proMessagesIds.includes(id),
        );

        // Update entities who need to be updated
        await Promise.all(
          updateProMessages.map(async (proMessage) => {
            if (proMessage.id) {
              await updateProMessage(proMessage.id, proMessage);
            }
          }),
        ).catch((error) => {
          ToastError(
            'Erreur',
            "Impossible d'enregistrer la fiche (update proMessages)",
          );
          throw error;
        });

        // ProPrices
        // Determine which objects should be inserted and format it for the query (insert objects that do not contains an id)
        const insertProPrices = values.proPrices
          .filter((proPrice) => !proPrice.id)
          .map(
            (proPrice): ProPriceInsertInput => ({
              ...proPrice,
              proViewId: proView.id,
            }),
          );

        // Determine which objects need to be updated (objects which are already in the database and therefore which contain an id)
        const updateProPrices = values.proPrices.filter(
          (proPrice) => proPrice.id,
        );

        // Get all ids before any modifications
        const proPricesInitialIds = proView.proPrices.map(
          (proPrice) => proPrice.id,
        );

        // Get all ids after any modifications
        const proPricesIds = values.proPrices.map((proPrice) => proPrice.id);

        // Determine which objects should be delete from the database (difference between before/after modifications)
        const deleteProPrices = proPricesInitialIds.filter(
          (id) => !proPricesIds.includes(id),
        );

        // Update entities who need to be updated
        await Promise.all(
          updateProPrices.map(async (proPrice) => {
            if (proPrice.id) {
              await updateProPrice(proPrice.id, proPrice);
            }
          }),
        ).catch((error) => {
          ToastError(
            'Erreur',
            "Impossible d'enregistrer la fiche (update proPrices)",
          );
          throw error;
        });

        // ProMediaObjects
        // Determine which objects should be inserted and format it for the query (insert objects that do not contains an id)
        const insertProMediaObjects = values.proPresentation.proMediaObjects
          .filter((proMediaObject) => !proMediaObject.id)
          .map(
            (proMediaObject): ProMediaObjectInsertInput => ({
              ...proMediaObject,
              proPresentationId: proView.proPresentation.id,
            }),
          );

        // Determine which objects need to be updated (objects which are already in the database and therefore which contain an id)
        const updateProMediaObjects =
          values.proPresentation.proMediaObjects.filter(
            (proMediaObject) => proMediaObject.id,
          );

        // Get all ids before any modifications
        const proMediaObjectsInitialIds =
          proView.proPresentation.proMediaObjects.map(
            (proMediaObject) => proMediaObject.id,
          );

        // Get all ids after any modifications
        const proMediaObjectsIds = values.proPresentation.proMediaObjects.map(
          (proMediaObject) => proMediaObject.id,
        );

        // Determine which objects should be delete from the database (difference between before/after modifications)
        const deleteProMediaObjects = proMediaObjectsInitialIds.filter(
          (proMediaObjectId) => !proMediaObjectsIds.includes(proMediaObjectId),
        );

        // Update entities who need to be updated
        await Promise.all(
          updateProMediaObjects.map(async (proMediaObject) => {
            if (proMediaObject.id) {
              await updateProMediaObject(proMediaObject.id, proMediaObject);
            }
          }),
        ).catch((error) => {
          ToastError(
            'Erreur',
            "Impossible d'enregistrer la fiche (update proMediaObjects)",
          );
          throw error;
        });

        // ProPeople (ProViewProPerson)
        const proPeopleInitialIds = proView.proPeople.map(
          ({ proPerson }) => proPerson.id,
        );

        const updateProPeople = values.proPeople
          .filter((proPerson) => proPeopleInitialIds.includes(proPerson.id))
          .map(
            (proPerson): ProViewProPersonSetInput => ({
              position: proPerson.position,
              isVisible: proPerson.isVisible,
              proPersonId: proPerson.id,
            }),
          );

        const insertProPeople = values.proPeople
          .filter((proPerson) => !proPeopleInitialIds.includes(proPerson.id))
          .map(
            (proPerson): ProViewProPersonInsertInput => ({
              proPersonId: proPerson.id,
              position: proPerson.position,
              isVisible: proPerson.isVisible,
              proViewId: proView.id,
            }),
          );

        const deleteProPeople = proPeopleInitialIds.filter(
          (proPersonId) =>
            !values.proPeople
              .map((proPerson) => proPerson.id)
              .includes(proPersonId),
        );

        // Updating ProViewProPerson (to persist new positions)
        await Promise.all(
          updateProPeople.map(async (proPerson) => {
            if (proPerson.proPersonId) {
              await updateProViewProPerson(
                proPerson.proPersonId,
                proView.id,
                proPerson,
              );
            }
          }),
        ).catch((error) => {
          ToastError(
            'Erreur',
            `Impossible d'enregister la fiche (update proViewProPerson)`,
          );
          throw error;
        });

        // Trades
        const tradesInitialIds = proView.trades.map(({ trade }) => trade.id);

        const insertTrades = values.trades
          .filter((tradeId) => !tradesInitialIds.includes(tradeId))
          .map(
            (tradeId): TradeProViewSetInput => ({
              proViewId: proView.id,
              tradeId: tradeId,
            }),
          );

        const deleteTrades = tradesInitialIds.filter(
          (tradeId) => !values.trades.includes(tradeId),
        );

        const insertMainTradeToRelatedProViewTrade =
          proView.proPresentation.proViews
            .filter(
              (relatedProView) =>
                relatedProView.id != proView.id &&
                !relatedProView.trades
                  .map((trade) => trade.trade.id)
                  .includes(values.proPresentation.mainTradeId as number),
            )
            .map(
              (tradeProView): TradeProViewSetInput => ({
                proViewId: tradeProView.id,
                tradeId: values.proPresentation.mainTradeId ?? undefined,
              }),
            );

        const isSecondaryTradeChanged =
          values.proPresentation.secondaryTradeId !==
          (proView.proPresentation.secondaryTrade?.id ?? null);

        const isSecondaryTradeRemovedFromSecondaryTradeField =
          isSecondaryTradeChanged &&
          values.proPresentation.secondaryTradeId === null;

        const shouldInsertSecondaryTradeToRelatedProViewTrade =
          // Insert  secondary trade when it changes and not removed from secondary trade field
          isSecondaryTradeChanged &&
          !isSecondaryTradeRemovedFromSecondaryTradeField;

        const insertSecondaryTradeToRelatedProViewTrade =
          shouldInsertSecondaryTradeToRelatedProViewTrade
            ? proView.proPresentation.proViews
                .filter(
                  (relatedProView) =>
                    relatedProView.id != proView.id &&
                    !relatedProView.trades
                      .map((trade) => trade.trade.id)
                      .includes(
                        values.proPresentation.secondaryTradeId as number,
                      ),
                )
                .map(
                  (tradeProView): TradeProViewSetInput => ({
                    proViewId: tradeProView.id,
                    tradeId:
                      values.proPresentation.secondaryTradeId ?? undefined,
                  }),
                )
            : [];

        // Cities
        const citiesInitialIds = proView.cities.map((city) => city.city.id);

        const initialParentIds = proView.cities
          .filter(({ city }) => city.parentId)
          .map(({ city }) => city.parentId);

        const insertCities = values.cities.map(
          (city): CityProViewSetInput => ({
            proViewId: proView.id,
            cityId: city.id,
            type: city.type,
          }),
        );

        // Insert parent city if at least one district is selected (if Lyon 7 is selected, insert Lyon)
        values.cities
          .filter((city) => city.parentId)
          .map((city) => {
            if (
              !insertCities.find(
                (insertCity) => insertCity.cityId === city.parentId,
              )
            ) {
              insertCities.push({
                proViewId: proView.id,
                cityId: city.parentId ?? undefined,
                type: city.type,
              });
            }
          });

        const deleteCities = citiesInitialIds.filter(
          (id) => !values.cities.find((city) => city.id === id),
        );

        // Delete parent city if no district is selected
        for (const initialParentId of initialParentIds) {
          if (
            !values.cities.find((city) => city.parentId === initialParentId) &&
            initialParentId
          ) {
            deleteCities.push(initialParentId);
          }
        }

        // Certifications
        const certificationsInitialIds =
          proView.proPresentation.certifications.map(
            ({ certification }) => certification.id,
          );

        const insertCertifications = values.proPresentation.certifications
          .filter((cityId) => !certificationsInitialIds.includes(cityId))
          .map(
            (certificationId): ProCertificationSetInput => ({
              certificationId: certificationId,
              proPresentationId: proPresentationId,
            }),
          );

        const deleteCertifications = certificationsInitialIds.filter(
          (certificationId) =>
            !values.proPresentation.certifications.includes(certificationId),
        );

        // Colleagues
        const colleaguesInitialIds = proView.colleagues.map(
          ({ proView }) => proView.id,
        );

        const insertColleagues = values.colleagues
          .filter((colleagueId) => !colleaguesInitialIds.includes(colleagueId))
          .map(
            (colleagueId): ProViewProViewSetInput => ({
              proViewSource: proView.id,
              proViewTarget: colleagueId,
            }),
          );

        const deleteColleagues = colleaguesInitialIds.filter(
          (colleagueId) => !values.colleagues.includes(colleagueId),
        );

        // Accounts
        const accountInitialIds = proView.accounts.map(
          ({ account }) => account.id,
        );

        const insertAccounts = values.accounts
          .filter((accountId) => !accountInitialIds.includes(accountId))
          .map(
            (accountId): AccountProViewSetInput => ({
              proViewId: proView.id,
              accountId,
            }),
          );

        const deleteAccounts = accountInitialIds.filter(
          (accountId) => !values.accounts.includes(accountId),
        );

        await updatePro({
          proView: {
            ...proViewInput,
            priceComment: values.priceComment?.trim(),
            openingHours: values.openingHours.trim(),
            telephones: values.telephones.map((telephone) =>
              formatE164Telephone(telephone),
            ),
            callTrackingTarget: {
              ...values.callTrackingTarget,
              telephone: formatE164Telephone(
                values.callTrackingTarget.telephone,
              ),
            },
            callTrackingForward: values.callTrackingForward
              ? {
                  ...values.callTrackingForward,
                  telephone: values.callTrackingForward.telephone
                    ? formatE164Telephone(values.callTrackingForward.telephone)
                    : '',
                }
              : undefined,
          },
          proPresentationId: proView.proPresentation.id,
          proPresentation: {
            ...proPresentationInput,
            name: values.proPresentation.name.trim(),
            profile: values.proPresentation.profile.trim(),
            hobbies: values.proPresentation.hobbies.trim(),
          },
          deleteProHolidays,
          deleteProMediaObjects,
          deleteProMessages,
          insertProHolidays,
          insertProPostalAddressOne: {
            ...proPostalAddressInput,
            proViewId: proView.id,
            geoCoordinates: addCrs(proPostalAddressInput.geoCoordinates),
            cityGeoCoordinates: addCrs(
              proPostalAddressInput.cityGeoCoordinates,
            ),
          },
          deleteGoogleReviewLinks,
          insertProMessages,
          insertProPrices,
          deleteProPrices,
          insertProMediaObjects,
          insertTrades,
          deleteTrades,
          insertColleagues,
          deleteColleagues,
          insertCities,
          deleteCities,
          insertCertifications,
          deleteCertifications,
          insertProPeople,
          deleteProPeople,
          insertAccounts,
          deleteAccounts,
          insertMainTradeToRelatedProViewTrade,
          insertSecondaryTradeToRelatedProViewTrade,
        }).catch((error) => {
          ToastError('Erreur', "Impossible d'enregistrer la fiche");
          throw error;
        });

        if (
          proView.proPresentation.slug &&
          proView.proPresentation.slug !== values.proPresentation.slug
        ) {
          await createSlugRedirect(
            proView.proPresentation.slug,
            values.proPresentation.slug,
          ).catch(() => {
            ToastError('Erreur', 'Impossible de créer la redirection de slug');
          });
        }

        if (isCitiesChanged) {
          updateProIntervention(proView.id).catch(() => {
            ToastError(
              'Erreur',
              'La mise à jour des interventions du pro a échoué',
            );
          });
        }

        await fetchProForm();

        ToastSuccess('Succès', 'Fiche mise à jour');
      }
    },
    [proView],
  );

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      await fetchProForm();
    };
    fetch();
  }, [fetchProForm]);

  if (!proView) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  const initialValues: ProFormUpdateFields = {
    hasContact: proView.hasContact !== undefined ? proView.hasContact : true,
    hasQuotation:
      proView.hasQuotation !== undefined ? proView.hasQuotation : true,
    averageResponseDays: proView.averageResponseDays,
    averageQuotationDays: proView.averageQuotationDays,
    averageProjectDays: proView.averageProjectDays,
    priceComment: proView.priceComment || '',
    status: proView.status,
    avatarHash: proView.avatarHash || '',
    originalAvatarHash: proView.originalAvatarHash || null,
    openingHours: proView.openingHours || '',
    telephones: proView.telephones || [],
    isSolicitationsDisabled: proView.isSolicitationsDisabled,
    isSmsDisabled: proView.isSmsDisabled,
    isCallsDisabled: proView.isCallsDisabled,
    isCallAlertActivated: proView.isCallAlertActivated,
    isCallRecordable: proView.isCallRecordable,
    isLocalBusiness: proView.isLocalBusiness,
    datePublished: proView.datePublished || null,
    proPeople: proView.proPeople.map((proPerson) => ({
      id: proPerson.proPerson.id,
      position: proPerson.position,
      isVisible: proPerson.isVisible,
    })),
    callTrackingTarget: proView.callTrackingTarget || {
      name: '',
      telephone: '',
    },

    callTrackingForward: proView.callTrackingForward || {
      isActive: false,
      name: '',
      telephone: '',
      timeout: 12,
    },
    mainAccountId: proView.mainAccount?.id ?? null,
    trades: proView.trades.map(({ trade }) => trade.id),
    accounts: proView.accounts.map(({ account }) => account.id),
    colleagues: proView.colleagues.map(({ proView }) => proView.id),
    cities: proView.cities.map((city) => ({
      id: city.city.id,
      type: city.type as 'include' | 'exclude',
      parentId: city.city.parentId ?? null,
    })),
    proMessages: proView.proMessages.map(
      (proMessage): ProMessageFields => ({
        id: proMessage.id,
        body: proMessage.body,
        isPermanent: proMessage.isPermanent,
        startDate: proMessage.startDate || null,
        endDate: proMessage.endDate || null,
      }),
    ),
    proPrices: proView.proPrices.map(
      (proPrice): ProPriceFields => ({
        id: proPrice.id,
        title: proPrice.title,
        content: proPrice.content,
        isRepair: proPrice.isRepair,
      }),
    ),
    proHolidays: proView.proHolidays.map(
      (proHoliday): ProHolidayFields => ({
        id: proHoliday.id,
        startDate: proHoliday.startDate,
        endDate: proHoliday.endDate,
      }),
    ),
    proPostalAddress: {
      street: proView.proPostalAddress?.street || '',
      city: proView.proPostalAddress?.city || '',
      postalCode: proView.proPostalAddress?.postalCode,
      displayOption: proView.proPostalAddress?.displayOption || 'complete',
      geoCoordinates:
        proView.proPostalAddress?.geoCoordinates &&
        isPoint(proView.proPostalAddress?.geoCoordinates)
          ? proView.proPostalAddress?.geoCoordinates
          : undefined,
      cityGeoCoordinates:
        proView.proPostalAddress?.cityGeoCoordinates &&
        isPoint(proView.proPostalAddress?.cityGeoCoordinates)
          ? proView.proPostalAddress?.cityGeoCoordinates
          : undefined,
      formatted: proView.proPostalAddress
        ? formattedPostalAddress(proView.proPostalAddress)
        : undefined,
    },

    proPresentation: {
      name: proView.proPresentation.name,
      slug: proView.proPresentation.slug,
      expertise1: proView.proPresentation.expertise1 || '',
      expertise11: proView.proPresentation.expertise11 || '',
      expertise12: proView.proPresentation.expertise12 || '',
      expertise13: proView.proPresentation.expertise13 || '',
      expertise14: proView.proPresentation.expertise14 || '',
      expertise15: proView.proPresentation.expertise15 || '',
      expertise16: proView.proPresentation.expertise16 || '',
      expertise2: proView.proPresentation.expertise2 || '',
      expertise21: proView.proPresentation.expertise21 || '',
      expertise22: proView.proPresentation.expertise22 || '',
      expertise23: proView.proPresentation.expertise23 || '',
      expertise24: proView.proPresentation.expertise24 || '',
      expertise25: proView.proPresentation.expertise25 || '',
      expertise26: proView.proPresentation.expertise26 || '',
      expertise3: proView.proPresentation.expertise3 || '',
      expertise31: proView.proPresentation.expertise31 || '',
      expertise32: proView.proPresentation.expertise32 || '',
      expertise33: proView.proPresentation.expertise33 || '',
      expertise34: proView.proPresentation.expertise34 || '',
      expertise35: proView.proPresentation.expertise35 || '',
      expertise36: proView.proPresentation.expertise36 || '',
      extra1: proView.proPresentation.extra1 || '',
      extra2: proView.proPresentation.extra2 || '',
      extra3: proView.proPresentation.extra3 || '',
      extra4: proView.proPresentation.extra4 || '',
      extra5: proView.proPresentation.extra5 || '',
      mainTradeId: proView.proPresentation.mainTrade?.id || null,
      secondaryTradeId: proView.proPresentation.secondaryTrade?.id || null,
      profile: proView.proPresentation.profile || '',
      hobbies: proView.proPresentation.hobbies || '',
      proMediaObjects: proView.proPresentation.proMediaObjects.map(
        (proMediaObject): ProMediaObjectFields => ({
          id: proMediaObject.id,

          hash: proMediaObject.hash,
          position: proMediaObject.position,
        }),
      ),
      certifications: proView.proPresentation.certifications.map(
        ({ certification }) => certification.id,
      ),
      proOrganizationId: proView.proPresentation.proOrganizationId || null,
    },
    note: proView.note || undefined,
    googleReviewLinks: proView.proPresentation.googleReviewLinks.map(
      (googleReviewLink) => ({
        url: googleReviewLink?.url || '',
        id: googleReviewLink?.id || undefined,
        proViewIds: googleReviewLink.proViews?.map((proView) => proView.id),
        proPresentationId: googleReviewLink.proPresentationId,
      }),
    ),
    isMainCityCenterExcluded: proView.isMainCityCenterExcluded || false,
    isFitInGuide: true,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={true}
      validationSchema={proFormUpdateValidationSchema(
        proView.proPresentation.slug,
      )}
    >
      <ProFormUpdateView
        proViewId={proView.id}
        proPresentationId={proView.proPresentation.id}
        mainTradeName={proView.proPresentation.mainTrade?.name}
        bilikZone={proView.bilikZone}
        proOrganizationId={proView?.proPresentation.proOrganizationId}
      />
    </Formik>
  );
};

export default ProFormUpdateContainer;
