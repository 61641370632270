import { CopyButton } from 'components/copy-button/copy-button';
import env from 'env';
import React, { FunctionComponent } from 'react';
import { Divider, Grid } from 'semantic-ui-react';

type ProWidgetProps = {
  proViewId: number;
  proPresentationId: number;
};

const ProLinks: FunctionComponent<ProWidgetProps> = ({
  proViewId,
  proPresentationId,
}) => {
  const proReviewFormLinkText = `${env.SITE_URL}/avis?pro=${proPresentationId}`;
  const proReviewsLinkText = `${env.SITE_URL}/pro/fiche/${proViewId}#reviews`;
  const proPageLinkText = `${env.SITE_URL}/pro/fiche/${proViewId}`;

  const proReviewFormLink = (
    <>
      <a
        href={`${env.SITE_URL}/avis?pro=${proPresentationId}`}
        className="anchor"
      >
        {proReviewFormLinkText}
      </a>
    </>
  );

  const proReviewsLink = (
    <a
      href={`${env.SITE_URL}/pro/fiche/${proViewId}#reviews`}
      className="anchor"
    >
      {proReviewsLinkText}
    </a>
  );

  const proPageLink = (
    <a href={`${env.SITE_URL}/pro/fiche/${proViewId}`} className="anchor">
      {proPageLinkText}
    </a>
  );

  return (
    <>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={10}>
            Lien court vers le formulaire d&apos;avis : {proReviewFormLink}
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            <CopyButton
              text={'Copier le lien'}
              selection={proReviewFormLinkText}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={10}>
            Lien court vers avis : {proReviewsLink}
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            <CopyButton
              text={'Copier le lien'}
              selection={proReviewsLinkText}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={10}>
            Lien court vers fiche : {proPageLink}
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            <CopyButton text={'Copier le lien'} selection={proPageLinkText} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
    </>
  );
};

export default ProLinks;
