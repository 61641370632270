import {
  BilikZoneInsertInput,
  CityUpdates,
  useBilikZoneFormCreateMutation,
  useUpdateCityMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';

import BilikZoneFormCreateContainer from './bilik-zone-form-create-container';

const BilikZoneFormCreateRepository: FunctionComponent = () => {
  const [insertBilikZoneOne] = useBilikZoneFormCreateMutation();

  const [updateCity] = useUpdateCityMutation();

  const createBilikZone = useCallback(
    async (bilikZone: BilikZoneInsertInput) => {
      const { data } = await insertBilikZoneOne({
        variables: {
          object: bilikZone,
        },
      });

      return data?.insertBilikZoneOne?.id;
    },
    [insertBilikZoneOne],
  );

  const updateCities = useCallback(
    async (cityUpdates: CityUpdates[]) => {
      return await updateCity({
        variables: {
          updateCities: cityUpdates,
        },
      });
    },
    [updateCity],
  );

  return (
    <BilikZoneFormCreateContainer
      createBilikZone={createBilikZone}
      updateCities={updateCities}
    />
  );
};

export default BilikZoneFormCreateRepository;
