import { QueryResult } from '@apollo/client';
import { apiClient } from 'axios-client';
import {
  AccountProViewInsertInput,
  CityProViewSetInput,
  Exact,
  ProFormUpdateQuery,
  ProHolidayInsertInput,
  ProHolidaySetInput,
  ProMediaObjectInsertInput,
  ProMediaObjectSetInput,
  ProMessageInsertInput,
  ProPriceInsertInput,
  ProMessageSetInput,
  ProPostalAddressInsertInput,
  ProPresentationSetInput,
  ProViewProPersonSetInput,
  ProViewProViewSetInput,
  ProViewSetInput,
  TradeProViewSetInput,
  useProFormCreateSlugRedirectMutation,
  useProFormUpdateMutation,
  useProFormUpdateProHolidayMutation,
  useProFormUpdateProMediaObjectMutation,
  useProFormUpdateProMessageMutation,
  useProFormUpdateProViewProPersonMutation,
  useProFormUpdateQueryLazyQuery,
  useProFormUpdateProPriceMutation,
  GoogleReviewLinkInsertInput,
  useProFormUpdateGoogleReviewLinkMutation,
  GoogleReviewLinkSetInput,
  useProFormUpdateProViewGoogleReviewLinkMutation,
  useProFormCreateGoogleReviewLinkMutation,
  ProCertificationSetInput,
  useProFormUpdateFetchProOrganizationLazyQuery,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import ProFormUpdateContainer from './pro-form-update-container';

export type ProFormUpdateProParams = {
  insertProHolidays: ProHolidayInsertInput[];
  deleteProHolidays: number[];
  insertProPostalAddressOne: ProPostalAddressInsertInput;
  insertProMediaObjects: ProMediaObjectInsertInput[];
  deleteProMediaObjects: number[];
  insertProPrices: ProPriceInsertInput[];
  deleteProPrices: number[];
  deleteGoogleReviewLinks: number[];
  insertProMessages: ProMessageInsertInput[];
  deleteProMessages: number[];
  proPresentationId: number;
  proPresentation: ProPresentationSetInput;
  proView: ProViewSetInput;
  insertTrades: TradeProViewSetInput[];
  insertMainTradeToRelatedProViewTrade: TradeProViewSetInput[];
  insertSecondaryTradeToRelatedProViewTrade: TradeProViewSetInput[];
  deleteTrades: number[];
  insertCities: CityProViewSetInput[];
  deleteCities: number[];
  insertCertifications: ProCertificationSetInput[];
  deleteCertifications: number[];
  insertColleagues: ProViewProViewSetInput[];
  deleteColleagues: number[];
  insertProPeople: ProViewProPersonSetInput[];
  deleteProPeople: number[];
  insertAccounts: AccountProViewInsertInput[];
  deleteAccounts: number[];
};

type ProFormUpdateRepositoryProps = {
  proViewId: number;
  proPresentationId: number;
};

const ProFormUpdateRepository: FunctionComponent<
  ProFormUpdateRepositoryProps
> = ({ proViewId, proPresentationId }) => {
  const [proFormUpdateQuery, { data }] = useProFormUpdateQueryLazyQuery();

  const [fetchProOrganizationQuery] =
    useProFormUpdateFetchProOrganizationLazyQuery();

  const fetchProOrganization = useCallback(
    (proOrganizationId: number) =>
      fetchProOrganizationQuery({ variables: { id: proOrganizationId } }),
    [],
  );

  const fetchProForm = useCallback(async (): Promise<
    QueryResult<
      ProFormUpdateQuery,
      Exact<{
        id: number;
        yesterday?: any;
      }>
    >
  > => {
    return proFormUpdateQuery({
      variables: {
        id: proViewId,
        yesterday: new Date(new Date().setDate(new Date().getDate() - 1)),
      },
    });
  }, [proViewId]);

  const [updateGoogleReviewLinkMutation] =
    useProFormUpdateGoogleReviewLinkMutation();

  const updateGoogleReviewLink = useCallback(
    async (
      id: number,
      googleReviewLink: GoogleReviewLinkSetInput,
    ): Promise<void> => {
      await updateGoogleReviewLinkMutation({
        variables: {
          googleReviewLinkId: id,
          googleReviewLink,
        },
      });
    },
    [],
  );

  const [updateProViewGoogleReviewLinkMutation] =
    useProFormUpdateProViewGoogleReviewLinkMutation();

  const updateProViewGoogleReviewLink = useCallback(
    async (proViewId: number, googleReviewLinkId: number): Promise<void> => {
      await updateProViewGoogleReviewLinkMutation({
        variables: {
          googleReviewLinkId,
          proViewId,
        },
      });
    },
    [],
  );

  const [updateProHolidayMutation] = useProFormUpdateProHolidayMutation();

  const updateProHoliday = useCallback(
    async (id: number, proHoliday: ProHolidaySetInput): Promise<void> => {
      await updateProHolidayMutation({
        variables: {
          proHolidayId: id,
          proHoliday,
        },
      });
    },
    [],
  );

  const [updateProMessageMutation] = useProFormUpdateProMessageMutation();

  const updateProMessage = useCallback(
    async (id: number, proMessage: ProMessageSetInput): Promise<void> => {
      await updateProMessageMutation({
        variables: {
          proMessageId: id,
          proMessage,
        },
      });
    },
    [],
  );

  const updateProIntervention = useCallback(
    async (proViewId: number): Promise<void> => {
      return await apiClient.post('/form/pro-intervention/update', {
        proViewId,
      });
    },
    [],
  );

  const [updateProPriceMutation] = useProFormUpdateProPriceMutation();

  const updateProPrice = useCallback(
    async (id: number, proPrice: ProPriceInsertInput): Promise<void> => {
      await updateProPriceMutation({
        variables: {
          proPriceId: id,
          proPrice,
        },
      });
    },
    [],
  );

  const [updateProMediaObjectMutation] =
    useProFormUpdateProMediaObjectMutation();

  const updateProMediaObject = useCallback(
    async (
      id: number,
      proMediaObject: ProMediaObjectSetInput,
    ): Promise<void> => {
      await updateProMediaObjectMutation({
        variables: {
          proMediaObjectId: id,
          proMediaObject,
        },
      });
    },
    [],
  );

  const [updateProViewProPersonMutation] =
    useProFormUpdateProViewProPersonMutation();

  const updateProViewProPerson = useCallback(
    async (
      proPersonId: number,
      proViewId: number,
      proViewProPerson: ProViewProPersonSetInput,
    ): Promise<void> => {
      await updateProViewProPersonMutation({
        variables: {
          proPersonId,
          proViewId,
          proViewProPerson,
        },
      });
    },
    [],
  );

  const [updateProMutation] = useProFormUpdateMutation();

  const updatePro = useCallback(
    async (variables: ProFormUpdateProParams) => {
      await updateProMutation({
        variables: {
          insertProHolidays: variables.insertProHolidays,
          insertProMediaObjects: variables.insertProMediaObjects,
          insertProPrices: variables.insertProPrices,
          deleteProPrices: variables.deleteProPrices,
          deleteGoogleReviewLinks: variables.deleteGoogleReviewLinks,
          insertProMessages: variables.insertProMessages,
          proPresentationId: variables.proPresentationId,
          proPresentation: variables.proPresentation,
          proViewId: proViewId,
          proView: variables.proView,
          insertProPostalAddressOne: variables.insertProPostalAddressOne,
          deleteProHolidays: variables.deleteProHolidays,
          deleteProMediaObjects: variables.deleteProMediaObjects,
          deleteProMessages: variables.deleteProMessages,
          insertTrades: variables.insertTrades,
          deleteTrades: variables.deleteTrades,
          insertCities: variables.insertCities,
          insertCertifications: variables.insertCertifications,
          deleteCertifications: variables.deleteCertifications,
          insertColleagues: variables.insertColleagues,
          deleteCities: variables.deleteCities,
          deleteColleagues: variables.deleteColleagues,
          insertProPeople: variables.insertProPeople,
          deleteProPeople: variables.deleteProPeople,
          insertAccounts: variables.insertAccounts,
          deleteAccounts: variables.deleteAccounts,
          insertMainTradeToRelatedProViewTrade:
            variables.insertMainTradeToRelatedProViewTrade,
          insertSecondaryTradeToRelatedProViewTrade:
            variables.insertSecondaryTradeToRelatedProViewTrade,
        },
      });
    },
    [proViewId],
  );

  const [createSlugRedirectMutation] = useProFormCreateSlugRedirectMutation();

  const createSlugRedirect = useCallback(
    async (fromSlug: string, toSlug: string) => {
      await createSlugRedirectMutation({
        variables: {
          input: {
            fromslug: fromSlug,
            toslug: toSlug,
            slugtype: 2,
          },
        },
      });
    },
    [],
  );

  const [createGoogleReviewLinkMutation] =
    useProFormCreateGoogleReviewLinkMutation();

  const moveContract = useCallback(
    async (
      oldProOrganizationId: number,
      newProOrganizationId: number,
      proViewId: number,
    ) => {
      const result = await apiClient.get(
        `/attachment/pro-documents-informations?pro_organization_id=${oldProOrganizationId}`,
      );

      const contractKey = result.data.find(
        (document) =>
          document.key.includes(`contrat-${proViewId}.pdf`) &&
          document.metaData?.type === 'contract',
      )?.key;

      if (contractKey) {
        await apiClient.post('/attachment/move', {
          bucket: 'bilik',
          fromKey: contractKey,
          toKey: contractKey.replace(
            `/${oldProOrganizationId}/`,
            `/${newProOrganizationId}/`,
          ),
        });
      } else {
        console.warn('No contract to move');
      }
    },
    [],
  );

  const createGoogleReviewLink = useCallback(
    async (googleReviewLink: GoogleReviewLinkInsertInput) => {
      const { data } = await createGoogleReviewLinkMutation({
        variables: {
          input: {
            url: googleReviewLink.url,
            proPresentationId: googleReviewLink.proPresentationId,
          },
        },
      });

      return data?.insertGoogleReviewLinkOne?.id;
    },
    [],
  );
  return (
    <ProFormUpdateContainer
      proView={data?.proViewByPk}
      proPresentationId={proPresentationId}
      updateGoogleReviewLink={updateGoogleReviewLink}
      updateProViewGoogleReviewLink={updateProViewGoogleReviewLink}
      updateProMediaObject={updateProMediaObject}
      updateProMessage={updateProMessage}
      updateProPrice={updateProPrice}
      updateProIntervention={updateProIntervention}
      updateProHoliday={updateProHoliday}
      updateProViewProPerson={updateProViewProPerson}
      updatePro={updatePro}
      fetchProForm={fetchProForm}
      createSlugRedirect={createSlugRedirect}
      createGoogleReviewLink={createGoogleReviewLink}
      moveContract={moveContract}
      fetchProOrganization={fetchProOrganization}
    />
  );
};

export default ProFormUpdateRepository;
