import { Geometry, GeoJsonObject, Point, GeoJSON, Polygon } from 'geojson';
import { buffer, centroid } from '@turf/turf';
import { FeatureGroup, LatLngLiteral, Layer } from 'leaflet';

export const includeColor = '#0c637b';

export const excludeColor = '#eb5a2c';

export const toGeoJsonObject = (geometry?: Geometry): GeoJsonObject =>
  geometry as GeoJsonObject;

export const isPoint = (geometry: Geometry): geometry is Point =>
  geometry.type === 'Point';

export const layerToGeoJSON = (layer: Layer): GeoJSON =>
  (layer as unknown as FeatureGroup).toGeoJSON();

export const addCrs = (geometry?: Geometry) => {
  if (!geometry) {
    return undefined;
  }
  return {
    ...geometry,
    crs: { type: 'name', properties: { name: 'EPSG:4326' } },
  };
};

export const toGeometryGeoJSON = (
  latlng: LatLngLiteral,
  radius?: number,
): Geometry | undefined => {
  return buffer(
    {
      type: 'Point',
      coordinates: [latlng.lng, latlng.lat],
    },
    radius ?? 1,
  )?.geometry as Polygon;
};

export const getLatLngCenter = (geometry: Geometry): LatLngLiteral => {
  const center = centroid(geometry).geometry.coordinates;

  return {
    lng: center[0],
    lat: center[1],
  };
};
