import { Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils';
import { Loader } from 'semantic-ui-react';
import {
  ProReviewInvitationInsertInput,
  ProReviewInvitationsFormCreateQuery,
} from 'generated/graphql';
import { ProReviewInvitationsFormFields } from './pro-review-invitations-form-fields.type';
import { ProReviewInvitationsFormValidationSchema } from './pro-review-invitations-form-validation-schema';
import { ProReviewInvitationsFormView } from './pro-review-invitations-form-view';
import { useNavigate } from 'react-router-dom';
import {
  ProReviewInvitationsFormCreateRepositoryProReview,
  ProReviewInvitationsFormCreateRepositoryProReviewInvitation,
} from './pro-review-invitations-form-create-repository';

type Props = {
  proPresentationId: number;
  proViewId?: number;
  proReviews?: ProReviewInvitationsFormCreateRepositoryProReview;
  proReviewInvitations?: ProReviewInvitationsFormCreateRepositoryProReviewInvitation;
  createProReviewInvitations: (
    proReviewInvitations: ProReviewInvitationInsertInput[],
  ) => Promise<unknown>;
  loading: boolean;
};

export const ProReviewInvitationsFormCreateContainer: FunctionComponent<
  Props
> = ({
  proPresentationId,
  proViewId,
  proReviews,
  proReviewInvitations,
  createProReviewInvitations,
  loading,
}) => {
  const history = useNavigate();
  const onSubmit = useCallback(
    async (
      values: ProReviewInvitationsFormFields,
      actions: FormikHelpers<ProReviewInvitationsFormFields>,
    ) => {
      try {
        await createProReviewInvitations(values.proReviewInvitations);

        ToastSuccess('Succès', `Demandes d'avis enregistrées.`);
        actions.setSubmitting(false);

        if (proViewId) history(`/pros/update/${proViewId}/review_invitations`);
        else actions.resetForm();
      } catch (e) {
        ToastError('Erreur', "Impossible d'enregistrer les demandes d'avis");
      }
    },
    [createProReviewInvitations],
  );

  const defaultProReviewInvitation = {
    proPresentationId,
    isBounce: false,
    givenName: '',
    familyName: '',
    email: '',
    isAutorespondActive: true,
  };

  const initialValues: ProReviewInvitationsFormFields = useMemo(() => {
    return {
      proReviewInvitations: [defaultProReviewInvitation],
    };
  }, []);

  if (loading) {
    return (
      <Loader style={{ marginTop: '20px' }} inline>
        Chargement...
      </Loader>
    );
  }

  return (
    <Formik
      validateOnChange={true}
      validationSchema={ProReviewInvitationsFormValidationSchema()}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <ProReviewInvitationsFormView
        defaultProReviewInvitation={defaultProReviewInvitation}
        proReviews={proReviews}
        proReviewInvitations={proReviewInvitations}
      />
    </Formik>
  );
};
