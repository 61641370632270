import React, { FunctionComponent } from 'react';

import { BilikZoneFormUpdate } from './bilik-zone-form';
import {
  Button,
  Container,
  Grid,
  GridColumn,
  Icon,
  Loader,
} from 'semantic-ui-react';
import { Navigate, useParams } from 'react-router';
import { useBilikZoneUpdatePageQuery } from 'generated/graphql';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import ContentContainer from 'components/content-container/content-container';
import { Link } from 'react-router-dom';

const BilikZoneUpdatePage: FunctionComponent = () => {
  const params = useParams<{ bilikZoneId?: string }>();
  const bilikZoneId = Number(params.bilikZoneId);
  const { data, loading } = useBilikZoneUpdatePageQuery({
    variables: {
      id: bilikZoneId,
    },
  });

  if (loading) {
    return (
      <Content>
        <Loader
          style={{ marginTop: '50px' }}
          size="large"
          active
          inline="centered"
        >
          Chargement...
        </Loader>
      </Content>
    );
  }

  if (!data?.bilikZoneByPk) return <Navigate to="/404" replace />; // Bilik zone not found

  return (
    <Content>
      <ContentHeader>
        <ContentHeaderTitle>
          Zone : {data.bilikZoneByPk?.name}
        </ContentHeaderTitle>
      </ContentHeader>
      <ContentBody>
        <Grid columns="1">
          <GridColumn>
            <ContentContainer>
              <BilikZoneFormUpdate />
            </ContentContainer>
          </GridColumn>
        </Grid>
      </ContentBody>
    </Content>
  );
};

export default BilikZoneUpdatePage;
