import { apiClient } from 'axios-client';
import {
  ProReviewInvitationInsertInput,
  useProReviewInvitationsFormCreateMutation,
  useProReviewInvitationsFormCreateQuery,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback, useMemo } from 'react';

import { ProReviewInvitationsFormCreateContainer } from './pro-review-invitations-form-create-container';

type Props = {
  proPresentationId: number;
  proViewId?: number;
};

export type ProReviewInvitationsFormCreateRepositoryProReview = {
  id: number;
  dateCreated: string;
  givenName: string;
  familyName: string;
  email: string;
  proPresentation: {
    id: number;
    slug: string;
    mainTrade?: { slug: string };
    proViews: {
      id: number;
      bilikZone: { slug: string };
    }[];
  };
}[];

export type ProReviewInvitationsFormCreateRepositoryProReviewInvitation = {
  id: number;
  dateCreated: string;
  givenName: string;
  familyName: string;
  email: string;
  proPresentation?: {
    id: number;
    slug: string;
    mainTrade?: { slug: string };
    proViews: {
      id: number;
    }[];
  };
}[];

export const ProReviewInvitationsFormCreateRepository: FunctionComponent<
  Props
> = ({ proPresentationId, proViewId }) => {
  const [proReviewInvitationsFormCreateMutation] =
    useProReviewInvitationsFormCreateMutation();

  const { data, loading } = useProReviewInvitationsFormCreateQuery({
    variables: {
      proPresentationId,
    },
  });

  // Concat all proPresentation reviews with all others reviews through proOrganizationId
  const proReviews: ProReviewInvitationsFormCreateRepositoryProReview = useMemo(
    () => [
      ...(data?.proPresentationByPk?.proReviews ?? []),
      ...(
        data?.proPresentationByPk?.proOrganization?.proPresentations ?? []
      ).flatMap((proPresentation) => proPresentation.proReviews),
    ],
    [data],
  );

  //Concat all proReviewInvitations with all others reviews through proOrganizationId
  const proReviewInvitations: ProReviewInvitationsFormCreateRepositoryProReviewInvitation =
    useMemo(
      () => [
        ...(data?.proPresentationByPk?.proReviewInvitations ?? []),
        ...(
          data?.proPresentationByPk?.proOrganization?.proPresentations ?? []
        ).flatMap((proPresentation) => proPresentation.proReviewInvitations),
      ],
      [data],
    );

  console.log(proReviewInvitations);

  const createProReviewInvitations = useCallback(
    async (proReviewInvitations: ProReviewInvitationInsertInput[]) => {
      return Promise.all(
        proReviewInvitations.map((proReviewInvitation) =>
          apiClient.post(`/form/review-invitation`, {
            proPresentation: proPresentationId,
            ...proReviewInvitation,
            namespace: 'bilik',
          }),
        ),
      );
    },
    [proReviewInvitationsFormCreateMutation, data],
  );

  return (
    <ProReviewInvitationsFormCreateContainer
      createProReviewInvitations={createProReviewInvitations}
      proPresentationId={proPresentationId}
      proReviews={proReviews}
      proReviewInvitations={proReviewInvitations}
      proViewId={proViewId}
      loading={loading}
    />
  );
};
