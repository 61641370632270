import Keycloak from 'keycloak-js';
import env from 'env';
import { apiClient } from 'axios-client';
import { getToken } from 'utils';

const keycloakConfig = {
  url: env.KEYCLOAK_AUTH_URL,
  realm: env.KEYCLOAK_REALM,
  clientId: env.KEYCLOAK_CLIENT_ID,
};

export const keycloak = new Keycloak(keycloakConfig);

export const createKeycloakUser = async (variables: {
  username: string;
  email: string;
  firstName?: string;
  lastName?: string;
  attributes?: Record<string, unknown>;
  credentials?: Array<{ type: string; value: string; temporary: boolean }>;
  enabled: boolean;
}): Promise<void> => {
  const keycloakCreateUserBodyData = {
    username: variables.username,
    firstName: variables.firstName,
    lastName: variables.lastName,
    email: variables.email,
    emailVerified: true,
    enabled: variables.enabled,
    attributes: variables.attributes,
    credentials: variables.credentials,
  };

  try {
    await apiClient.post(
      `${env.KEYCLOAK_AUTH_URL}/admin/realms/${env.KEYCLOAK_REALM}/users`,
      keycloakCreateUserBodyData,
    );
  } catch (err) {
    console.error('createKeycloakUser failed', err);
  }
};

const removeUserFromGroup = async (
  userId: string,
  groupId: string,
): Promise<number | null> => {
  try {
    const { status } = await apiClient.delete(
      `${env.KEYCLOAK_AUTH_URL}/admin/realms/${env.KEYCLOAK_REALM}/users/${userId}/groups/${groupId}`,
    );

    return status;
  } catch (error) {
    console.error('removeUserFromGroup failed', error);
    return null;
  }
};

/**
 * it add a keycloak user to keycloak group
 * if no group is found, we look for subGroup
 * This method return status code so we can check the success
 */
export const addKeycloakUserGroup = async (
  userId: string,
  groupId: string,
): Promise<number | null> => {
  try {
    const { status } = await apiClient.put(
      `${env.KEYCLOAK_AUTH_URL}/admin/realms/${env.KEYCLOAK_REALM}/users/${userId}/groups/${groupId}`,
      {},
    );

    return status;
  } catch (err) {
    console.error('addKeycloakUserGroup failed', err);
    return null;
  }
};

export const getGroupId = async (groupPath: string): Promise<string | null> => {
  try {
    const { data } = await apiClient.get(
      `${env.KEYCLOAK_AUTH_URL}/admin/realms/${env.KEYCLOAK_REALM}/group-by-path/${groupPath}`,
    );

    return data.id;
  } catch (err) {
    console.error('getProGroupId failed', err);
  }

  return null;
};

export const updateKeycloakUser = async (
  userId: string,
  user: {
    username?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    attributes?: Record<string, unknown>;
    credentials?: Array<{ type: string; value: string; temporary: boolean }>;
    enabled?: boolean;
  },
): Promise<number | null> => {
  try {
    const { status } = await apiClient.put(
      `${env.KEYCLOAK_AUTH_URL}/admin/realms/${env.KEYCLOAK_REALM}/users/${userId}`,
      user,
    );

    return status;
  } catch (error) {
    console.error('updateKeycloakUser failed', error);
    return null;
  }
};

export const updateKeycloakUserGroup = async (
  userId: string,
  oldGroupName?: string,
  newGroupName?: string,
): Promise<number | null> => {
  if (oldGroupName && newGroupName) {
    try {
      const oldBilikPersonGroupId = await getGroupId(oldGroupName);
      const newBilikPersonGroupId = await getGroupId(newGroupName);

      if (oldBilikPersonGroupId && newBilikPersonGroupId) {
        await removeUserFromGroup(userId, oldBilikPersonGroupId);
        const status = await addKeycloakUserGroup(
          userId,
          newBilikPersonGroupId,
        );

        return status;
      }
    } catch (err) {
      console.error(err);
    }
  }

  return null;
};

export const getKeycloakUserIdByEmail = async (
  email: string,
): Promise<string | null> => {
  try {
    const url = `${env.KEYCLOAK_AUTH_URL}/admin/realms/${
      env.KEYCLOAK_REALM
    }/users?email=${encodeURIComponent(email)}`;

    const { data: users } = await apiClient.get(url);

    const wantedUser = users.find((user) => user.email === email);
    if (wantedUser && wantedUser.id) {
      return wantedUser.id;
    }
  } catch (err) {
    console.error('getCreatedUserId failed', err);
  }

  return null;
};

export const getUsers = async (first = 0): Promise<Array<any>> => {
  const users: any[] = [];
  try {
    const { data } = await apiClient.get(
      `${env.KEYCLOAK_AUTH_URL}/admin/realms/${env.KEYCLOAK_REALM}/users?first=${first}`,
    );

    users.push(...data);
  } catch (error) {
    console.error('getUsers failed', error);
  }
  return users;
};

export const executeKeycloakEmailActionOnUser = async (
  userId: string,
  actions: string[],
): Promise<number | null> => {
  const { status } = await apiClient.put(
    `${env.KEYCLOAK_AUTH_URL}/admin/realms/${env.KEYCLOAK_REALM}/users/${userId}/execute-actions-email`,
    actions,
  );

  return status;
};
