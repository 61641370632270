import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { getIn, useFormikContext } from 'formik';
import { LatLng, Layer, LeafletMouseEvent } from 'leaflet';
import {
  ProFormUpdateFields,
  ProPostalAddressField,
} from 'pages/pros/pro-form/pro-form-update-fields-type';
import { useGetCitiesLazyQuery } from 'generated/graphql';
import L from 'leaflet';
import { toGeometryGeoJSON } from 'utils/geometry';
import { Polygon, Feature, Geometry, FeatureCollection } from 'geojson';
import { ToastError } from 'utils';
import { area, booleanIntersects } from '@turf/turf';
import {
  FeatureIntervention,
  OtherProperties,
} from './interventions-field-repository';
import InterventionsFieldView from './interventions-field-view';

const interventionCollectionReducer = (
  state: FeatureCollection<Geometry>,
  action: {
    type: 'insert' | 'upsert' | 'update' | 'delete';
    intervention: Feature<Geometry>[];
  },
): FeatureCollection<Geometry> => {
  switch (action.type) {
    case 'insert':
      return {
        ...state,
        features: [
          ...state.features,
          ...action.intervention.filter((intervention) => {
            return !state.features.some((city) => {
              return city.properties!.id === intervention.properties!.id;
            });
          }),
        ],
      };
    case 'upsert':
      const citiesToInsert = action.intervention.filter(
        (newCity) =>
          !state.features.some(
            (city) => city.properties!.id === newCity.properties!.id,
          ),
      );

      const citiesToUpdate = action.intervention.filter((newCity) =>
        state.features.some(
          (city) =>
            city.properties!.id === newCity.properties!.id &&
            city.properties!.type !== newCity.properties!.type,
        ),
      );

      return {
        ...state,
        features: [
          ...state.features.filter(
            (city) =>
              !citiesToInsert.some(
                (newCity) => newCity.properties!.id === city.properties!.id,
              ),
          ),
          ...citiesToInsert,
        ].map(
          (city) =>
            citiesToUpdate.find(
              (newCity) => newCity.properties!.id === city.properties!.id,
            ) || city,
        ),
      };

    case 'update':
      const updatedCities = {
        ...state,
        // Replace cities with the updated ones
        features: state.features.map(
          (city) =>
            action.intervention.find(
              (c) => c.properties!.id === city.properties!.id,
            ) ?? city,
        ),
      };

      return updatedCities;

    case 'delete':
      return {
        ...state,
        // Delete cities from the state
        features: state.features.filter(
          (city) =>
            !action.intervention.find(
              (c) => c.properties!.id === city.properties!.id,
            ),
        ),
      };
  }
};

const createInitialState = (): FeatureCollection<Geometry> => ({
  type: 'FeatureCollection',
  features: [],
});

type InterventionsFieldContainerProps = {
  name: string;
  getCitiesQuery: ReturnType<typeof useGetCitiesLazyQuery>[0];
  proPostalAddress?: ProPostalAddressField;
  interventionTypes: string[];
  defaultMapCenter?: LatLng | null;
  otherFeatures?: FeatureCollection<Geometry, OtherProperties>;
  label?: string;
};

const InterventionsFieldContainer: FunctionComponent<
  InterventionsFieldContainerProps
> = ({
  name,
  getCitiesQuery,
  defaultMapCenter,
  interventionTypes,
  proPostalAddress,
  otherFeatures,
  label,
}) => {
  const { initialValues, setFieldValue } = useFormikContext();

  const defaultValues = useMemo(
    () => getIn(initialValues, name),
    [initialValues, name],
  );

  const [loadingInterventions, setLoadingInterventions] = useState(false);

  const [selectionMode, setSelectionMode] = useState<'click' | 'lasso'>(
    defaultValues.length ? 'click' : 'lasso',
  );

  const [interventions, setInterventions] = useReducer(
    interventionCollectionReducer,
    undefined,
    createInitialState,
  );

  useEffect(() => {
    if (!defaultValues?.length) return;

    setLoadingInterventions(true);

    getCitiesQuery({
      variables: {
        where: {
          id: {
            _in: defaultValues?.map((city) => city.id),
          },
        },
      },
    }).then(({ data }) => {
      setInterventions({
        type: 'insert',
        intervention:
          data?.city.map((intervention) => ({
            type: 'Feature',
            geometry: intervention.area as Polygon,
            properties: {
              ...intervention,
              type: defaultValues?.find((city) => city.id === intervention.id)
                ?.type,
            },
          })) ?? [],
      });
      setLoadingInterventions(false);
    });
  }, []);

  useEffect(() => {
    if (!interventions.features.length) return;

    setFieldValue(
      name,
      interventions.features
        .filter((intervention) =>
          ['include', 'exclude'].includes(intervention.properties?.type),
        )
        .map((intervention): ProFormUpdateFields['cities'][0] => ({
          id: Number(intervention.properties!.id),
          type: intervention.properties!.type,
          parentId: intervention.properties!.parentId,
        })),
    );
  }, [interventions]);

  const onPolygonDrawed = useCallback(
    (e: { shape: string; layer: Layer }) => {
      setLoadingInterventions(true);
      const { layer, shape } = e;

      layer.remove();

      if (shape === 'clear') {
        setInterventions({
          type: 'delete',
          intervention: interventions.features.filter((city) => {
            const gneu = booleanIntersects(
              (layer as L.Polygon).toGeoJSON().geometry,
              city.geometry,
            );
            return gneu;
          }),
        });
        return;
      }

      const geometry =
        layer instanceof L.Circle
          ? (toGeometryGeoJSON(
              layer.getLatLng(),
              layer.getRadius() / 1000,
            ) as Polygon)
          : (layer as L.Polygon).toGeoJSON().geometry;

      if (area(geometry) / 1000000 > 20000) {
        // La
        ToastError(
          'Attention',
          'La zone tracée ne peut pas dépasser les 20000km2 pour des soucis de performance',
        );
        return;
      }

      if (!geometry) return;

      getCitiesQuery({
        variables: {
          where: {
            area: {
              _stIntersects: geometry,
            },
          },
        },
      }).then(({ data }) => {
        setInterventions({
          type: 'upsert',
          intervention:
            data?.city.map((intervention) => ({
              type: 'Feature',
              geometry: intervention.area as Polygon,
              properties: {
                ...intervention,
                type: shape === 'exclude' ? 'exclude' : 'include',
              },
            })) ?? [],
        });

        setSelectionMode('click');
        setLoadingInterventions(false);
      });
    },
    [interventions, selectionMode],
  );

  const onDeleteFeatures = useCallback(async () => {
    if (selectionMode === 'click') {
      setInterventions({
        type: 'delete',
        intervention: interventions.features,
      });

      setSelectionMode('lasso');
    }

    if (selectionMode === 'lasso') {
      setSelectionMode('click');
    }
  }, [selectionMode, interventions]);

  const onEachCityInterventionFeature = useCallback(
    (feature: FeatureIntervention, layer: Layer) => {
      layer.on({
        click: () => {
          const types = [...interventionTypes, 'touches'];

          const cityUpdated = {
            ...feature,
            properties: {
              ...feature.properties,
              // change the type of the city to the next one in the order
              type: types[
                (types.indexOf(feature.properties.type) + 1) % types.length
              ],
            },
          };

          setInterventions({
            type: 'update',
            intervention: [cityUpdated],
          });
        },
      });
    },
    [],
  );

  const onClickMap = useCallback(
    (e: LeafletMouseEvent) => {
      if (selectionMode === 'click') {
        getCitiesQuery({
          variables: {
            where: {
              area: {
                _stIntersects: toGeometryGeoJSON(e.latlng, 0.1),
              },
            },
          },
        }).then(({ data }) => {
          if (
            interventions.features.find(
              (city) => city.properties?.id === data?.city[0]?.id,
            )
          ) {
            return;
          }

          setInterventions({
            type: 'insert',
            intervention:
              data?.city.map((intervention) => ({
                type: 'Feature',
                geometry: intervention.area as Polygon,
                properties: {
                  ...intervention,
                  type: 'include',
                },
              })) ?? [],
          });
        });
      }
    },
    [selectionMode, interventions],
  );

  return (
    <InterventionsFieldView
      loadingInterventions={loadingInterventions}
      selectionMode={selectionMode}
      onDeleteFeatures={onDeleteFeatures}
      otherFeatures={otherFeatures}
      onPolygonDrawed={onPolygonDrawed}
      onClickMap={onClickMap}
      interventions={interventions}
      defaultMapCenter={defaultMapCenter}
      proPostalAddress={proPostalAddress}
      onEachCityInterventionFeature={onEachCityInterventionFeature}
      label={label}
    />
  );
};

export default InterventionsFieldContainer;
