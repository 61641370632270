import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';

import { getIn, useFormikContext } from 'formik';
import { CityFieldBilikZoneQuery } from 'generated/graphql';
import { CityFieldView } from './city-field-view';
import {
  LeafletMouseEvent,
  FeatureGroup as LeafletFeatureGroup,
} from 'leaflet';

import { Geometry } from 'geojson';

type CityFieldContainerProps = {
  label?: string;
  labelCoordinates?: string;
  helpText?: string;
  bilikZoneId?: number | null;
  cityFieldName: string;
  buttonText?: string;
  geometryFieldName: string;
  onPlaceSelected?: (value) => void;
  fetchBilikZone: () => Promise<void>;
  getCityRectangle: (cityName: string) => Promise<Geometry>;
  bilikZone?: CityFieldBilikZoneQuery;
  required?: boolean;
  shouldDisplayCities: boolean;
  drawMode: 'Point' | 'Polygon';
};

export type CityFieldModalState = {
  open: boolean;
  error?: string;
  geometry?: Geometry;
  bounds?: Geometry;
};

export const CityFieldContainer: FunctionComponent<CityFieldContainerProps> = ({
  label,
  labelCoordinates,
  helpText,
  cityFieldName,
  geometryFieldName,
  buttonText,
  onPlaceSelected,
  fetchBilikZone,
  getCityRectangle,
  bilikZone,
  shouldDisplayCities,
  drawMode,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const mapDrawRef = useRef<LeafletFeatureGroup>(null);

  const geometry: Geometry = useMemo(
    () => getIn(values, geometryFieldName),
    [values, geometryFieldName],
  );

  /**
   *  CityForm : name
   *  proForm : city
   *  bilikZoneForm : mainCity
   *  proReviewForm : addressLocality
   */
  const cityName: string | undefined = useMemo(
    () => (cityFieldName ? getIn(values, cityFieldName) : undefined),
    [values],
  );

  const [modalState, setModalState] = useReducer(
    (state: CityFieldModalState, newState: Partial<CityFieldModalState>) => ({
      ...state,
      ...newState,
    }),
    {
      open: false,
    },
  );

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      await fetchBilikZone();
    };
    fetch();
  }, []);

  const onPlaceAutocompleteSelected = useCallback(
    async (place: google.maps.places.PlaceResult) => {
      const cityName = place.address_components?.find(
        (component) => component.types[0] === 'locality',
      )?.long_name;
      setFieldTouched(cityFieldName);
      setFieldValue(cityFieldName, cityName, true);

      setFieldTouched(geometryFieldName);

      if (cityName && place.geometry) {
        switch (drawMode) {
          case 'Point':
            setFieldValue(
              geometryFieldName,
              {
                type: 'Point',
                coordinates: [
                  place.geometry.location.lng(),
                  place.geometry.location.lat(),
                ],
              },
              true,
            );
            break;
          case 'Polygon':
            const cityRectangle = await getCityRectangle(cityName);

            if (cityRectangle) {
              setFieldValue(geometryFieldName, cityRectangle, true);
            }
            break;
          default:
            break;
        }
      }

      if (onPlaceSelected) {
        onPlaceSelected(place);
      }
    },
    [onPlaceSelected, getCityRectangle],
  );

  const onOpenMapModal = useCallback(async () => {
    const bounds = cityName ? await getCityRectangle(cityName) : undefined;

    setModalState({
      ...modalState,
      open: true,
      bounds,
      geometry,
    });
  }, [bilikZone, geometry, cityName]);

  const onGeoCoordinatesChange = useCallback(
    (event: LeafletMouseEvent) => {
      setModalState({
        geometry: {
          type: 'Point',
          coordinates: [event.latlng.lng, event.latlng.lat],
        },
      });
    },
    [modalState],
  );

  const onValidateModalChange = useCallback(() => {
    switch (drawMode) {
      case 'Point':
        if (modalState.geometry) {
          setFieldValue(geometryFieldName, modalState.geometry, true);
          setModalState({ open: false });
        } else {
          setModalState({
            error: 'Veuillez placer un marqueur sur la carte',
          });
        }
        break;
    }
  }, [modalState.geometry, mapDrawRef]);

  return (
    <CityFieldView
      label={label}
      labelCoordinates={labelCoordinates}
      helpText={helpText}
      buttonText={buttonText}
      bilikZone={bilikZone}
      cityFieldName={cityFieldName}
      geometryFieldName={geometryFieldName}
      onPlaceSelected={onPlaceAutocompleteSelected}
      onOpenMapModal={onOpenMapModal}
      onValidateModalChange={onValidateModalChange}
      onGeoCoordinatesChange={onGeoCoordinatesChange}
      cityName={cityName}
      modalState={modalState}
      shouldDisplayCities={shouldDisplayCities}
      setModalState={setModalState}
      drawMode={drawMode}
      mapDrawRef={mapDrawRef}
      geometry={geometry}
    />
  );
};
