import React, { FunctionComponent } from 'react';
import { ProPostalAddressField } from 'pages/pros/pro-form/pro-form-update-fields-type';
import { useGetCitiesLazyQuery } from 'generated/graphql';
import { Polygon, FeatureCollection, Geometry } from 'geojson';
import InterventionsFieldContainer from './interventions-field-container';
import { LatLng } from 'leaflet';

export type InterventionField = {
  type: 'include' | 'exclude' | 'touches';
  city: {
    id: number;
    name: string;
    area: Polygon;
  };
};

export type FeatureIntervention = {
  type: 'Feature';
  geometry: Polygon;
  properties: InterventionField;
};

export type OtherProperties = {
  color: string;
  fillColor: string;
  fillOpacity: number;
  stroke: boolean;
};

type InterventionsFieldProps = {
  name: string;
  proPostalAddress?: ProPostalAddressField;
  interventionTypes: string[];
  defaultMapCenter: LatLng | null;
  otherFeatures?: FeatureCollection<Geometry, OtherProperties>;
  label?: string;
};

export const InterventionsFieldRepository: FunctionComponent<
  InterventionsFieldProps
> = ({
  name,
  proPostalAddress,
  interventionTypes,
  otherFeatures,
  defaultMapCenter,
  label,
}) => {
  const [getCitiesQuery] = useGetCitiesLazyQuery();

  return (
    <InterventionsFieldContainer
      getCitiesQuery={getCitiesQuery}
      otherFeatures={otherFeatures}
      defaultMapCenter={defaultMapCenter}
      name={name}
      interventionTypes={interventionTypes}
      proPostalAddress={proPostalAddress}
      label={label}
    />
  );
};
