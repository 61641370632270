import React, { Fragment, FunctionComponent } from 'react';
import { Divider, Grid, GridColumn, List } from 'semantic-ui-react';
import { range } from 'remeda';
import FormField from 'components/form-field/form-field';
import ProLinks from 'pages/pros/pro-form/pro-links/pro-links';
import GoogleReviewLinkField from 'components/google-review-link-field/google-review-link-field';
import ProLabels from '../pro-labels/pro-labels';
import ProPreviewGuide from '../pro-preview-guide/pro-preview-guide';
import Label from 'components/label/label';

type ProPresentationRouteProps = {
  proViewId: number;
  bilikZoneId: number;
  proPresentationId: number;
};

const ProPresentationRoute: FunctionComponent<ProPresentationRouteProps> = ({
  proViewId,
  proPresentationId,
}) => (
  <Fragment>
    <FormField
      type="text"
      label="Nom de la fiche"
      placeholder="Ex: Brunier peinture"
      name="proPresentation.name"
      required
    />
    <FormField
      type="text"
      label="Slug"
      helpText="Le slug est la version normalisée du nom. Il ne contient généralement que des lettres minuscules non accentuées, des chiffres et des traits d’union."
      placeholder="Ex: brunier-peinture"
      name="proPresentation.slug"
      required
    />
    <Divider horizontal style={{ marginTop: '50px' }}>
      Savoirs-faire
    </Divider>
    <Grid>
      <GridColumn width={11}>
        {range(1, 4).map((index) => (
          <Fragment key={`proPresentation.expertise.${index}`}>
            <FormField
              type="text"
              label={`Savoir faire ${index}`}
              name={`proPresentation.expertise${index}`}
            />
            <List ordered style={{ marginLeft: '1em' }}>
              {range(1, 7).map((indexChild) => (
                <List.Item
                  key={`proPresentation.expertise${index}${indexChild}`}
                >
                  <List.Content>
                    <FormField
                      type="text"
                      name={`proPresentation.expertise${index}${indexChild}`}
                    />
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Fragment>
        ))}
      </GridColumn>
      <GridColumn width={5}>
        <div style={{ position: 'sticky', top: '40px' }}>
          <Label>Prévisualisation du guide :</Label>
          {/* Set ProPreviewGuide Sticky */}
          <ProPreviewGuide proViewId={proViewId} />
        </div>
      </GridColumn>
    </Grid>
    <ProLabels proPresentationId={proPresentationId} />
    <Divider horizontal style={{ marginTop: '50px' }}>
      Profil
    </Divider>
    <FormField
      type="textarea"
      placeholder="..."
      label="Qui je suis ? / Qui sommes nous ?"
      name="proPresentation.profile"
      rows={13}
    />
    <FormField
      type="textarea"
      placeholder="..."
      label="Ce que j’aime faire / Ce que nous aimons faire"
      name="proPresentation.hobbies"
      rows={13}
    />
    <Divider horizontal style={{ marginTop: '50px' }}>
      Lien(s) pour avis Google
    </Divider>
    <GoogleReviewLinkField
      proPresentationId={proPresentationId}
      proViewId={proViewId}
    />
    <Divider horizontal style={{ marginTop: '50px' }}>
      Liens
    </Divider>
    <ProLinks proViewId={proViewId} proPresentationId={proPresentationId} />
  </Fragment>
);

export default ProPresentationRoute;
