import React, { FunctionComponent } from 'react';
import Map from 'components/map/map';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import './interventions-field.css';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { ProPostalAddressField } from 'pages/pros/pro-form/pro-form-update-fields-type';
import Label from 'components/label/label';
import { excludeColor, includeColor } from 'utils/geometry';
import { Point } from 'geojson';
import MapDraw from 'components/map/map-draw/map-draw';
import MapGeoJSON from 'components/map/map-geojson/map-geojson';
import { Tooltip } from 'react-leaflet';
import { Feature, Geometry, FeatureCollection } from 'geojson';
import { LatLng, Layer, LeafletMouseEvent } from 'leaflet';
import {
  FeatureIntervention,
  OtherProperties,
} from './interventions-field-repository';

const getGeometryStyle = (feature?: Feature<Geometry>) => {
  switch (feature?.properties?.type) {
    case 'include':
      return {
        color: includeColor,
        fillColor: includeColor,
        fillOpacity: 0.3,
        stroke: true,
      };
    case 'exclude':
      return {
        color: excludeColor,
        fillColor: excludeColor,
        fillOpacity: 0.3,
        stroke: true,
      };
    case 'touches':
      return {
        color: '#000000',
        fillColor: '#000000',
        fillOpacity: 0,
        stroke: true,
      };
    default:
      return {
        color: '#ffffff',
        fillColor: '#ffffff',
        fillOpacity: 0.1,
        stroke: true,
      };
  }
};

type InterventionsFieldViewProps = {
  label?: string;
  proPostalAddress?: ProPostalAddressField;
  loadingInterventions?: boolean;
  selectionMode: string;
  onDeleteFeatures: () => void;
  otherFeatures?: FeatureCollection<Geometry, OtherProperties>;
  defaultMapCenter?: LatLng | null;
  onPolygonDrawed: (e: { shape: string; layer: Layer }) => void;
  interventions: FeatureCollection<Geometry>;
  onClickMap: (e: LeafletMouseEvent) => void;
  onEachCityInterventionFeature: (
    feature: FeatureIntervention,
    layer: Layer,
  ) => void;
};

const InterventionsFieldView: FunctionComponent<
  InterventionsFieldViewProps
> = ({
  proPostalAddress,
  selectionMode,
  otherFeatures,
  defaultMapCenter,
  interventions,
  loadingInterventions,
  label,
  onDeleteFeatures,
  onClickMap,
  onPolygonDrawed,
  onEachCityInterventionFeature,
}) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 12,
        }}
      >
        <div>
          {selectionMode === 'lasso' && (
            <p style={{ color: 'green', padding: 0, margin: 0 }}>
              Dessinez une zone à l&apos;aide des boutons situés en bas à droite
            </p>
          )}
          {selectionMode === 'click' && (
            <p style={{ color: 'green', padding: 0, margin: 0 }}>
              Cliquez sur une ville pour la sélectionner, vous pouvez cliquer
              sur une zone vide pour ajouter une ville
            </p>
          )}
        </div>
        {selectionMode === 'click' && (
          <Button negative basic onClick={onDeleteFeatures}>
            <Icon name="trash alternate outline" />
            Supprimer toutes les villes
          </Button>
        )}
      </div>

      <Map
        key={`map-${selectionMode}`}
        style={{ height: `700px` }}
        doubleClickZoom={false}
        zoom={10}
        eventHandlers={{
          click: (e) => onClickMap(e),
        }}
        center={defaultMapCenter ?? [50, 3]}
      >
        {loadingInterventions && (
          <div
            style={{
              marginTop: '25px',
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%)',
              zIndex: 9999,
              backgroundColor: '#fff',
              animation: 'fadein 1s',
              borderRadius: '10px',
              border: '2px solid rgba(0, 0, 0, 0.2)',
              minWidth: '125px',
              minHeight: '70px',
              textAlign: 'center',
              paddingTop: '8px',
              fontSize: '15px',
            }}
          >
            <Loader active inline="centered" />
            Chargement...
          </div>
        )}
        {selectionMode === 'lasso' && (
          <MapDraw
            options={{
              position: 'bottomright',
              editControls: false,
              drawPolygon: false,
              rotateMode: false,
              cutPolygon: false,
            }}
            add={(map) => {
              map.pm.Toolbar.copyDrawControl('Circle', {
                name: 'circle',
                title: 'Dessiner un cercle',
                block: 'options',
                toggle: true,
                className: 'polygon-circle',
              });
              map.pm.Toolbar.copyDrawControl('Polygon', {
                name: 'include',
                title: 'Dessiner une zone',
                block: 'options',
                toggle: true,
                className: 'include',
              });
              map.pm.Draw['circle'].setOptions({
                hintlineStyle: { color: includeColor, dashArray: [5, 5] },
                templineStyle: { color: includeColor },
              });
              map.pm.Draw['include'].setOptions({
                hintlineStyle: { color: includeColor, dashArray: [5, 5] },
                templineStyle: { color: includeColor },
              });
            }}
            onCreate={onPolygonDrawed}
          />
        )}
        {interventions.features.map((intervention) => {
          return (
            <MapGeoJSON
              key={`city-${intervention.properties?.id}-${intervention.properties?.type}`}
              data={intervention}
              onEachFeature={
                selectionMode === 'click'
                  ? onEachCityInterventionFeature
                  : undefined
              }
              pmIgnore={true}
              style={getGeometryStyle(intervention)}
            >
              <Tooltip direction="top" offset={[0, -10]} opacity={1}>
                {intervention.properties?.name}
              </Tooltip>
            </MapGeoJSON>
          );
        })}
        {proPostalAddress?.geoCoordinates &&
        proPostalAddress?.displayOption === 'complete' ? (
          <MapGeoJSON
            data={proPostalAddress.geoCoordinates as Point}
            interactive={false}
            pmIgnore={true}
          />
        ) : null}
        {proPostalAddress?.cityGeoCoordinates &&
        proPostalAddress?.displayOption === 'city' ? (
          <MapGeoJSON
            data={proPostalAddress.cityGeoCoordinates as Point}
            interactive={false}
            pmIgnore={true}
          />
        ) : null}
        {otherFeatures && (
          <MapGeoJSON
            key={`other-features`}
            data={otherFeatures}
            style={(feature) => ({
              color: feature?.properties?.color,
              fillColor: feature?.properties?.fillColor,
              fillOpacity: feature?.properties?.fillOpacity,
              stroke: feature?.properties?.stroke,
            })}
            pmIgnore={true}
            interactive={false}
          />
        )}
      </Map>
    </>
  );
};

export default InterventionsFieldView;
