import { apiClient } from 'axios-client';
import React, { FunctionComponent, useCallback } from 'react';
import { Button, Card, Icon } from 'semantic-ui-react';
import { ToastError, ToastInfo, ToastSuccess } from 'utils';

type ProInterventionUpdateCardProps = Record<string, unknown>;

export const ProInterventionUpdateCard: FunctionComponent<
  ProInterventionUpdateCardProps
> = ({}) => {
  const onClickProInterventionupdate = useCallback(async (): Promise<void> => {
    ToastInfo('Info', 'mise à jour des interventions des pros en cours...');
    await apiClient.get(`/form/pro-intervention/update`).catch((error) => {
      if (error.status === 'Request failed with status code 504') {
        ToastInfo(
          'Info',
          'Request timeout, mise à jour des interventions toujours en cours...',
        );
      } else {
        ToastError('Erreur', 'Impossible de mettre à jour les interventions');
        throw error;
      }
    });

    ToastSuccess('Succès', 'les interventions ont été mises à jour');
  }, []);

  return (
    <Card>
      <Card.Content>
        <Icon size="large" style={{ float: 'right' }} name="car" />
        <Card.Header>Mettre à jour les interventions des pros</Card.Header>
        <Card.Description>
          Mettre à jour les interventions des pros
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button fluid primary onClick={onClickProInterventionupdate}>
          Executer
        </Button>
      </Card.Content>
    </Card>
  );
};
