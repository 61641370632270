import { KeycloakTokenParsed } from 'keycloak-js';
import env from 'env';

export const getToken = (): string | null =>
  localStorage.getItem('keycloakToken');
export const getTokenExp = (): number =>
  Number(localStorage.getItem('keycloakTokenExp'));
export const getRefreshToken = (): string | null =>
  localStorage.getItem('refreshToken');

export const setToken = (token: string): void =>
  localStorage.setItem('keycloakToken', token);
export const setTokenExp = (tokenExp: number): void =>
  localStorage.setItem('keycloakTokenExp', tokenExp.toString());
export const setRefreshToken = (refreshToken: string): void =>
  localStorage.setItem('refreshToken', refreshToken);

export const isTokenExpired = (): boolean => getTokenExp() > Date.now() - 1000;

export const isAuthorizedRoles = (tokenParsed: KeycloakTokenParsed) => {
  const AUTHORIZED_ROLES = ['bilik-person', 'admin'];

  const roles =
    tokenParsed.resource_access?.[env.KEYCLOAK_CLIENT_ID ?? 'undefined']?.roles;

  if (roles?.some((role) => AUTHORIZED_ROLES.includes(role))) {
    return true;
  }
  return false;
};
